import { Component } from "react";

class TitleComponent extends Component {
	constructor() {
		super();
		this.title = `NASA Neo asteroid watch`;
	}

	render() {
		return (
			<div className="my-4 jumbotron shadow-lg">
				<h2>{this.title}</h2>
			</div>
		);
	}
}

export default TitleComponent;
