import { Component } from "react";
import SpinnerLoader from "../loadercomponent/loader";

class DateComponent extends Component {
	constructor() {
		super();
		this.state = {
			today: null,
			tomorrow: null,
			asteroidData: null,
			loading: null,
		};
		this.apiKey = `WZ0w91AUKKFskqVMDvgaEw4syyAILbyuFjW3feLC`;
		this.returnDates = this.returnDates.bind(this);
		this.defaultFetch = this.defaultFetch.bind(this);
		this.returnThisDayConstruct = this.returnThisDayConstruct.bind(this);
		this.getInputSelection = this.getInputSelection.bind(this);
		this.styleSafe = {
			color: `lightgreen`,
			fontWeight: `bolder`,
		};
		this.styleDangerous = {
			color: `red`,
			fontWeight: `bolder`,
		};
	}

	returnDates(t, n) {
		let today = t;
		let tomorrow = n;
		let exactTomorrow = tomorrow.setDate(today.getDate() + 1);
		let dateOb = {
			today: String(today),
			tomorrow: String(new Date(exactTomorrow)),
		};
		return dateOb;
	}

	returnThisDayConstruct(dat) {
		let todayYear = new Date(dat).getUTCFullYear();
		let todayMonth =
			parseInt(new Date(dat).getUTCMonth() + 1) >= 10
				? String(new Date(dat).getUTCMonth() + 1)
				: `0` + String(new Date(dat).getUTCMonth() + 1);
		let todayDay =
			parseInt(new Date(dat).getUTCDate()) >= 10
				? String(new Date(dat).getUTCDate())
				: `0` + String(new Date(dat).getUTCDate());
		let constructedtodayDayString = String(
			todayYear + `-` + todayMonth + `-` + todayDay
		);
		return constructedtodayDayString;
	}

	fetchFunction(t) {
		if (this.state.today !== null && this.state.tomorrow !== null) {
			let nasaFetchLink = `https://neo.artefaktas.workers.dev/api`;
			let headerOb = {
				"start-date": String(this.state.today),
				"end-date": String(this.state.tomorrow),
			};
			async function getData() {
				let d = await fetch(nasaFetchLink, {
					headers: headerOb,
				});
				let jsonData = await d.json();
				return jsonData;
			}
			getData()
				.then((d) => {
					this.setState({
						asteroidData: d.near_earth_objects[t],
						loading: false,
					});
				})
				.catch((e) => {
					console.log(e);
				});
		}
	}

	defaultFetch() {
		let dateOb = this.returnDates(new Date(), new Date());
		let today = dateOb.today;
		let tomorrow = dateOb.tomorrow;

		let todayString = this.returnThisDayConstruct(today);
		let tomorrowString = this.returnThisDayConstruct(tomorrow);
		this.setState(
			{
				today: todayString,
				tomorrow: tomorrowString,
			},
			() => {
				this.setState(
					{
						loading: true,
					},
					() => {
						this.fetchFunction(todayString);
					}
				);
			}
		);
	}

	getInputSelection() {
		let selected = document.getElementById("calendar");
		let inputValueAsDate = selected.value;
		if (inputValueAsDate && inputValueAsDate !== this.state.today) {
			let inputValueAsNumber = selected.valueAsNumber;
			let dateOb = this.returnDates(
				new Date(inputValueAsNumber),
				new Date(inputValueAsNumber)
			);
			let today = dateOb.today;
			let tomorrow = dateOb.tomorrow;
			let todayString = this.returnThisDayConstruct(today);
			let tomorrowString = this.returnThisDayConstruct(tomorrow);
			this.setState(
				{
					today: todayString,
					tomorrow: tomorrowString,
				},
				() => {
					this.setState(
						{
							loading: true,
						},
						() => {
							this.fetchFunction(this.state.today);
						}
					);
				}
			);
		} else if (!inputValueAsDate) {
			selected.value = this.state.today;
		}
	}

	componentDidMount() {
		this.defaultFetch();
	}

	render() {
		return (
			<div>
				<div>
					<input
						id="calendar"
						className="form-control form-control-lg my-4"
						type="date"
						min={this.state.today !== null ? this.state.today : ""}
						onChange={this.getInputSelection}
						value={this.state.today}
					/>
				</div>
				<div className="my-4 table-responsive">
					<table className="table table-dark table-striped">
						<thead>
							<tr>
								<th scope="col">Id</th>
								<th scope="col">Name</th>
								<th scope="col">Hazardous</th>
								<th scope="col">Approach data</th>
								<th scope="col">Orbiting body</th>
								<th scope="col">Relative velocity</th>
								<th scope="col">Estimated diameter</th>
								<th scope="col">Miss distance</th>
							</tr>
						</thead>
						<tbody>
							{this.state.loading === true ? (
								<SpinnerLoader />
							) : this.state.asteroidData !== null ? (
								this.state.asteroidData.map((asteroid, k) => {
									return (
										<tr key={k}>
											<th scope="row">{asteroid.id}</th>
											<td>{asteroid.name}</td>
											<td>
												{asteroid.is_potentially_hazardous_asteroid ===
												false ? (
													<p style={this.styleSafe}>
														{String(asteroid.is_potentially_hazardous_asteroid)}
													</p>
												) : (
													<p style={this.styleDangerous}>
														{String(asteroid.is_potentially_hazardous_asteroid)}
													</p>
												)}
											</td>
											<td>
												{
													asteroid.close_approach_data[0]
														.close_approach_date_full
												}
											</td>
											<td>{asteroid.close_approach_data[0].orbiting_body}</td>
											<td>
												{parseFloat(
													asteroid.close_approach_data[0].relative_velocity
														.kilometers_per_hour
												).toFixed(3) + ` km/h`}
												<br />
												{parseFloat(
													asteroid.close_approach_data[0].relative_velocity
														.kilometers_per_second
												).toFixed(3) + ` km/s`}
											</td>
											<td>
												{`min: ` +
													parseFloat(
														asteroid.estimated_diameter.kilometers
															.estimated_diameter_min
													).toFixed(3) +
													` km`}
												<br />
												{`max: ` +
													parseFloat(
														asteroid.estimated_diameter.kilometers
															.estimated_diameter_max
													).toFixed(3) +
													` km`}
											</td>
											<td>
												{parseFloat(
													asteroid.close_approach_data[0].miss_distance
														.kilometers
												).toFixed(3) + ` km`}
											</td>
										</tr>
									);
								})
							) : (
								<tr></tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
		);
	}
}

export default DateComponent;
